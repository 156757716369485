import { domReady } from '@roots/sage/client';
import MicroModal from 'micromodal';
import 'slick-carousel';

/**
 * app.main
 */
const main = async (err) => {
  if (err) {
    console.error(err);
  }

  MicroModal.init({
    onShow: modal => console.info(`${modal.id} is shown`),
    onClose: modal => console.info(`${modal.id} is hidden`),
    openClass: 'is-open',
    disableScroll: true,
    disableFocus: false,
    awaitOpenAnimation: false,
    awaitCloseAnimation: false,
    debugMode: false
  });

  // sticky nav
  var navbar = document.getElementById("stickynav");

  if (navbar) {
    var sticky = navbar.offsetTop;

    function stickNav() {
      if (window.pageYOffset + 140 >= sticky) {
        navbar.classList.add("stuck")
      } else {
        navbar.classList.remove("stuck");
      }
    }

    window.onscroll = function () { stickNav() };
  }

  // anchor actions
  const anchors = document.querySelectorAll('.anchor-item');
  let anchorList = anchors[0];

  anchors.forEach((anchor) => {
    anchor.addEventListener('click', () => {
      anchorList.classList.remove('active');
      anchorList = anchor;
      anchorList.classList.add('active');
    });
  });

  let hash = location.hash.replace(/^#/, '');

  // scroll to 
  function scrollto(hash) {
    // make sure we have a hash and element to scroll to
    if ((hash !== "") && ($(hash).length > 0)) {
      $('html, body').animate({
        scrollTop: $(hash).offset().top - 80,
      }, 800, function () {
        if (history.pushState) {
          history.pushState(null, null, hash);
        } else {
          window.location.hash = hash;
        }
      });
    }
  }

  // Scroll to Position on hash link
  $(".anchor-item a").on('click', function (event) {
    if ((this.hash !== "") && ($(this.hash).length > 0)) {
      event.preventDefault();
      scrollto(this.hash);
    }
  });

  // scroll down on page load
  if (hash) {
    setTimeout(function () {
      scrollto('#' + hash);
    }, 550);
  }

  // check for wpl pagination
  var urlParams = new URLSearchParams(window.location.search);

  if (urlParams.has('wplpage')) {
    setTimeout(function () {
      $('html, body').animate({
        scrollTop: $('#properties').offset().top - 80,
      }, 800, function () {
        console.log('scrolling to properties');
      });
    }, 550);
  }

  // Set logo state
  let didScroll;

  $(window).scroll(function () {
    didScroll = true;
  });

  setInterval(function () {
    if (didScroll) {
      didScroll = false;
    }
  }, 250);

  // slick slider init
  let $sliders = $('.slider');
  if ($sliders.length > 0) {
    for (let s = 0; s < $sliders.length; s++) {
      initSlider($($sliders[s]));
    }
  }

  function initSlider($slider) {
    $slider.slick({
      dots: true,
      arrows: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="39.681" height="81.879" viewBox="0 0 39.681 81.879"><path id="Path_3972" data-name="Path 3972" d="M1.107,80.866l-2.213-2.025L34.508,39.926-1.107,1.013,1.107-1.013,38.574,39.926Z" transform="translate(38.574 80.866) rotate(180)" fill="#f8f8f8"/></svg></button>',
      nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="39.681" height="81.88" viewBox="0 0 39.681 81.88"><path id="Path_3967" data-name="Path 3967" d="M-11622.812-6587.828l-2.213-2.025,35.614-38.916-35.614-38.913,2.213-2.025,37.468,40.938Z" transform="translate(11625.024 6669.708)" fill="#f8f8f8"/></svg></button>',
    });
  }

  $(".inquire").on('click', function () {
    $('#menu-register a').trigger('click');
  });

  document.addEventListener('DOMContentLoaded', function(){ 
  
    // update to include if feedback comes in
    const sections = document.querySelectorAll(".section");
    const menu_links = document.querySelectorAll(".anchor-item a");
    const makeActive = (link) => menu_links[link].classList.add("active");
    const removeActive = (link) => menu_links[link].classList.remove("active");
    const removeAllActive = () => [...Array(sections.length).keys()].forEach((link) => removeActive(link));
    
    const sectionMargin = 200;
    let currentActive = 0;
  
    // listen for scroll events
    window.addEventListener("scroll", () => {
      
      const current = sections.length - [...sections].reverse().findIndex((section) => window.scrollY >= section.offsetTop - sectionMargin ) - 1
  
      if (current !== currentActive) {
        removeAllActive();
        currentActive = current;
        makeActive(current);
      }
    });
  }, false);

};

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(main);
import.meta.webpackHot?.accept(main);
